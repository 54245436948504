import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { buildFailedRoute } from '../util/routing';

const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      const { failedRoute, reward } = window.claim;

      if (failedRoute) {
        const route = buildFailedRoute(failedRoute, {
          dealId: props.match.params.dealId,
          reward
        });
        return <Redirect to={route} />;
      }

      return <Component {...props} />;
    }}
  />
);

export default ProtectedRoute;
