import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import RewardCard from "../RewardCard";
import SentRewardDetails from "../SentRewardDetails";
import { buildFailedRoute } from '../../util/routing';

const styles = theme => ({
  root: {},
  title: {
    marginBottom: '2rem'
  }
});

class ViewReward extends Component {
  componentDidMount() {
    const { failedRoute, reward } = window.claim;
    const { history, match } = this.props;

    // If there's a failedRoute, redirect to it immediately
    if (failedRoute) {
      const route = buildFailedRoute(failedRoute, {
        dealId: match.params.dealId,
        reward: reward
      });
      history.replace(route);
      return;
    }
  }


  render() {
    const { classes } = this.props;

    const {
      content: { image: salesDemoClaimImage },
      failedRoute
    } = window.claim;

    if (failedRoute) {
      return null;
    }
    return (
      <div className={classes.root}>
        <div>
          <RewardCard />

          {salesDemoClaimImage.src ? (
            <a href={salesDemoClaimImage.href}>
              <img
                src={salesDemoClaimImage.src}
                alt={salesDemoClaimImage.alt_text}
              />
            </a>
          ) : (
            <SentRewardDetails />
          )}
        </div>
      </div>
    );
  }
}

  ViewReward.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withStyles(styles)(ViewReward);
