import blue from "@material-ui/core/colors/blue";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import createBrowserHistory from "history/createBrowserHistory";
import deepmerge from "deepmerge";
import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";
import ClaimProvider from "./ClaimProvider";
import {
  AllClaimed,
  AlreadyClaimed,
  Congratulations,
  DealNotFound,
  IPLimit,
  InvalidAgent,
  NoMatch,
  PageTracker,
  SmsOptIn,
  ViewReward
} from "./components";
import ProtectedRoute from './components/ProtectedRoute';
import { MOCK_CLAIM } from "./mocks/classic";

if (process.env.NODE_ENV !== "production") {
  window.claim = MOCK_CLAIM;
}

const defaultTheme = {
  typography: {
    useNextVariants: true,
    htmlFontSize: 10,
    fontFamily: "'Open Sans', sans-serif"
  },
  palette: {
    primary: {
      main: window.claim.brand ? window.claim.brand.primaryColor : blue[500],
      contrastText: "#fff"
    },
    background: {
      default: "#fff"
    }
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: "none",
        height: "5.4rem"
      },
      label: {
        fontWeight: "700",
        fontSize: "1.6rem"
      }
    },
    MuiPaper: {
      root: {
        border: "1px solid lightgray"
      }
    },
    MuiTypography: {
      h6: {
        fontWeight: "700",
        fontSize: "2.4rem",
        lineHeight: "2.8rem"
      },
      subtitle1: {
        fontSize: "1.4rem",
        color: "#83899E",
        lineHeight: "2.4rem"
      },
      caption: {
        fontSize: "1.1rem",
        color: "#83899E",
        lineHeight: "1.8rem"
      },
      body1: {
        fontWeight: "700",
        color: "#83899E"
      }
    },
    MuiSwitch: {
      icon: {
        border: "1px solid lightgray"
      },
      iconChecked: {
        border: "none"
      }
    },
    MuiFormControlLabel: {
      label: {
        color: "#83899E"
      }
    },
    BrandLogo: {
      root: {}
    },
    CardImage: {
      root: {}
    },
    FinePrint: {
      root: {}
    },
    Navbar: {
      root: {},
      image: {},
    },
    RewardCard: {
      root: {}
    },
    RewardCardDescription: {
      root: {}
    }
  }
};

// Brand Theme

let brandTheme = {};
if (window.claim.brand && window.claim.brand.styles.materialUi) {
  try {
    brandTheme = JSON.parse(window.claim.brand.styles.materialUi);
  } catch (e) {
    if (e instanceof SyntaxError) {
      console.log("Recovered from SyntaxError in brand theme.");
    } else {
      throw e;
    }
  }
}

const theme = createMuiTheme(deepmerge(defaultTheme, brandTheme));
theme.shadows = []; // removes shadows from the theme.

// Brand Font
if (window.claim.brand && window.claim.brand.fontUrl) {
  let cssId = "customFontCss";
  if (!document.getElementById(cssId)) {
    let head = document.getElementsByTagName("head").item(0);
    let link = document.createElement("link");
    link.id = cssId;
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = window.claim.brand.fontUrl;
    link.media = "all";
    head.appendChild(link);
  }
}

const styles = theme => ({
  root: {
    width: "100%",
    maxWidth: 462,
    textAlign: "center",
    margin: "0 auto",
    padding: theme.spacing.unit * 2
  }
});

class App extends Component {
  constructor() {
    super();

    this.history = createBrowserHistory();
    if (
      window.claim.failedRoute &&
      !new RegExp(`${window.claim.failedRoute}`).test(window.location.pathname)
    ) {
      const { failedRoute, reward } = window.claim;
      var route = failedRoute;
      if (reward && reward.claimToken) {
        route = `${reward.claimToken}/${route}`;
      }
      this.history.replace(route);
    }

    const temp = document.querySelector(".temp_content")
    if (temp) {
      temp.parentNode.removeChild(temp);
    }

  }

  render() {
    const { classes } = this.props;
    return (
      <ClaimProvider>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <div className={classes.root}>
            <Router history={this.history}>
              <PageTracker>
                <Switch>
                  {/* Error Routes */}
                  <Route
                    exact
                    path="/(demo)?/:dealId?/:claimToken?/deal-not-found"
                    component={DealNotFound}
                  />
                  <Route
                    exact
                    path="/(demo)?/:dealId?/:claimToken?/invalid-token"
                    component={DealNotFound}
                  />
                  <Route
                    exact
                    path="/(demo)?/:dealId?/:claimToken?/too-soon"
                    component={NoMatch}
                  />
                  <Route
                    exact
                    path="/(demo)?/:dealId?/:claimToken?/all-claimed"
                    component={AllClaimed}
                  />
                  <Route
                    exact
                    path="/(demo)?/:dealId?/:claimToken?/already-claimed"
                    component={AlreadyClaimed}
                  />
                  <Route
                    exact
                    path="/:dealId?/:claimToken?/confirm-claim"
                    component={InvalidAgent}
                  />
                  <Route
                    exact
                    path="/:dealId?/:claimToken?/ip-limit"
                    component={IPLimit}
                  />
                  {/* Claim Route */}
                  <ProtectedRoute
                    exact
                    path="/(demo)?/:dealId/:claimToken/(congratulations)?"
                    component={Congratulations}
                  />
                  <Route
                    exact
                    path="/(demo)?/:dealId/:claimToken/sms-optin"
                    component={SmsOptIn}
                  />
                  <Route
                    exact
                    path="/(demo)?/:dealId/:claimToken/sms-optin"
                    component={SmsOptIn}
                  />
                  <ProtectedRoute
                    exact
                    path="/(demo)?/:dealId/:claimToken/view-reward"
                    component={ViewReward}
                  />
                  <Route
                    exact
                    path="/(demo)?/:dealId?/:claimToken?/try-again"
                    component={Error}
                  />
                  <Route component={NoMatch} />
                </Switch>
              </PageTracker>
            </Router>
          </div>
        </MuiThemeProvider>
      </ClaimProvider>
    );
  }
}

export default withStyles(styles)(App);
