import React, { Component } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import RewardCard from "../RewardCard";
import SendRewardForm from "../SendRewardForm";
import { duration } from "../../util";
import { buildFailedRoute } from '../../util/routing';

const styles = theme => ({
  root: {},
  slideIn: {
    transition: theme.transitions.create(["opacity", "transform"], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut
    })
  },
  slide: {
    transform: "translate(0, 10rem)"
  },
  fadeIn: {
    opacity: 100,
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.standard,
      easing: theme.transitions.easing.easeOut
    })
  },
  hidden: {
    opacity: 0
  },
  hide: {
    display: "none"
  },
  title: {
    marginBottom: "2rem"
  }
});

class Congratulations extends Component {
  state = {
    showCongrats: false,
    slideCongrats: false,
    showReward: false
  };

  componentDidMount() {
    const { failedRoute, reward } = window.claim;
    const { history, match } = this.props;

    // If there's a failedRoute, redirect to it immediately
    if (failedRoute) {
      const route = buildFailedRoute(failedRoute, {
        dealId: match.params.dealId,
        reward: reward
      });
      history.replace(route);
      return;
    }

    // Congrats fade in
    setTimeout(() => {
      this.setState({ showCongrats: true });
    }, 0);
    // Congrats slide
    setTimeout(() => {
      this.setState({ slideCongrats: true });
    }, 1000);
    // prize and form fade in
    setTimeout(() => {
      this.setState({ showReward: true });
    }, 2000);
  }

  getCongratsText = () => {
    const {
      content: { congrats },
      reward: { isBummer, bummerText }
    } = window.claim;
    if (isBummer && bummerText) {
      return bummerText;
    }

    return congrats || "Congratulations!";
  };

  getSubtitle = () => {
    const {
      reward: { fasterThan, upperResponseTime: fasterThanTime },
      campaign: { isInstant, isUno, isClaimByAction, isClaimByRandom }
    } = window.claim;

    if (isInstant || isClaimByAction || isClaimByRandom) {
      return "";
    } else if (isUno) {
      return `You responded in under ${duration(fasterThanTime)}`;
    }

    const inflection = fasterThan === 1 ? "person" : "people";
    return `You were faster than ${fasterThan.toLocaleString()} other ${inflection}.`;
  };

  render() {
    const { classes } = this.props;

    const {
      content: { image: salesDemoClaimImage },
      failedRoute
    } = window.claim;

    if (failedRoute) {
      return null;
    }

    return (
      <div className={classes.root}>
        <div
          className={clsx(classes.slideIn, {
            [classes.slide]: !this.state.slideCongrats,
            [classes.hidden]: !this.state.showCongrats
          })}
        >
          <Typography
            component="h1"
            variant="h6"
            className={classes.title}
            gutterBottom
          >
            <div dangerouslySetInnerHTML={{ __html: this.getCongratsText() }} />
          </Typography>
          <Typography component="h2" variant="subtitle1" gutterBottom>
            {this.getSubtitle()}
          </Typography>
        </div>

        <div
          className={clsx({
            [classes.fadeIn]: true,
            [classes.hidden]: !this.state.showReward
          })}
        >
          <RewardCard />

          {salesDemoClaimImage.src ? (
            <a href={salesDemoClaimImage.href}>
              <img
                src={salesDemoClaimImage.src}
                alt={salesDemoClaimImage.alt_text}
              />
            </a>
          ) : (
            <SendRewardForm
              history={this.props.history}
              match={this.props.match}
            />
          )}
        </div>
      </div>
    );
  }
}

Congratulations.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Congratulations);
